@import '../../index.scss';

footer {
    border-top: 1px solid $primary-gray;

    .container {
        padding-top: 2em;
        padding-bottom: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .copyright {
            font-size: clamp(1.5em, 2vw, 1.6em);
        }
    }
}

@media (max-width: 480px) {

    footer {
        .container {
            text-align: center;
            justify-content: center;

            .copyright {
                margin-bottom: 2em;
            }
        }
    }
}