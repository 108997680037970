@import '../../index.scss';

main {

    .container.page-container {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 10em;

        .text-block {
            font-size: clamp(1.5em, 2vw, 1.6em);
            line-height: 1.6em;
            padding: 0.5em 0;
        }

        .page-content {

            .title-block {
                font-size: clamp(2.0em, 2.5vw, 2.4em);
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-bottom: 10px;
            }

            .subtitle-block {
                font-size: clamp(1.6em, 2vw, 1.8em);
                font-weight: 600;
                color: $primary-white;
                opacity: 0.85;
                margin: 10px 0;
            }

            .terms-grid {
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 3.2em;

                .grid-item {
                    border-bottom: 1px solid $secondary-gray;
                    padding: 4em 0;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    main {
        .container.page-container {
            .page-content {
                .terms-grid {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
}