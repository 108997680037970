@font-face {
  font-family: 'Clash Display';
  src: url('./assets/fonts/ClashDisplay-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Clash Display';
  src: url('./assets/fonts/ClashDisplay-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Bold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Black.otf');
  font-weight: 700;
}

// Fonts
$font-body: 'Satoshi', sans-serif;
$font-heading: 'Clash Display', sans-serif;

// Colors
$primary-white: #dddddd;
$secondary-white: #999999;
$primary-black: #010101;
$primary-accent: #7462E5;
$primary-gray: #2d2d2d;
$secondary-gray: #545454;

*, *::after, *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::selection {
  color: $primary-black;
  background-color: $primary-white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $primary-black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: $primary-gray;
  border: 1px solid $primary-gray;
  border-radius: 100em;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $primary-gray;
// }

html {
  scroll-padding-top: 150px;
  overflow-x: hidden;
}

body {
  background-color: $primary-black;
  color: $primary-white;
  font-family: $font-body;
  font-size: 10px;
  // min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;

}

.container {
  padding-left: 44px;
  padding-right: 44px;
  z-index: 2;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  scroll-snap-type: y mandatory;
  scroll-padding: 50px 0px 0px 20px;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  color: $secondary-white;
}

.link {
  font-size: clamp(1.5em, 2vw, 1.6em);
  font-weight: 500;
  color: $primary-white;
  position: relative;
  // overflow: hidden;
  transition: all 150ms ease;
  display: block;
  max-width: fit-content;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 1px;
    width: 100%;
    background-color: $primary-accent;
    opacity: 0;
    transition: all 150ms ease;
  }

  &:hover {
    &::after {
      opacity: 1;
      bottom: -1px;
    }
  }
}

.button {
  padding: 12px 32px;
  border: 1px solid $secondary-gray;
  border-radius: 100em;
  font-size: clamp(1.4em, 2vw, 1.6em);
  font-weight: 400;
  color: $primary-white;
  transition: all 150ms ease;

  &:hover {
    transform: translateY(-4px);
  }

  &.button-fill {
    background-color: $primary-white;
    color: $primary-black;
    border: none;

  }

  &.button-outline {
    
    &:hover {
      border-color: $primary-white;
    }
  }

  &.button-alt {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $primary-gray;
  }
}

.highlight {
  color: $primary-white;
  font-weight: 500;
  text-underline-offset: 4px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.container {

  .page-title {
    font-size: clamp(2.8em, 6vw, 5.6em);
    font-family: $font-heading;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .page-content {
    margin: 5em 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .button-alt {
    height: 36px !important;
  }

  .flex {
    flex-direction: column;

    &.flex-reverse {
      flex-direction: column-reverse;
    }
  }
}