@import '../../index.scss';

main {
    margin-top: 20vh;
    // margin-bottom: 5em;

    .contact-container {
        display: flex;
        align-self: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .contact-column {
            width: 50%;
            margin-bottom: 10em;

            .element-title {
                font-size: clamp(1.8em, 6vw, 2.8em);
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1px;
                margin-bottom: 24px;
            }

            .contact-details {
                margin-bottom: 5em;

                a {
                    max-width: max-content;
                    margin-bottom: 1em;
                    min-width: 150px;
                }
            }
        }

        .left {
            padding-right: 5em;
        }

        .right {
            padding-left: 5em;
        }
    }
}

@media (max-width: 480px) {
    main {
        .container {
            flex-direction: column;

            .contact-column {
                width: 100%;
                padding: 0;
            }
        }
    }
}