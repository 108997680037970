@import '../../index.scss';

.contact-form {

    .form-input {
        padding-block: 1em;
        display: flex;
        flex-direction: column;
        max-width: 750px;

        label {
            font-size: clamp(1.3em, 2vw, 1.6em);
            padding-bottom: 8px;
            font-weight: 500;

            &::after {
                content: '*';
                color: $primary-accent;
                padding-left: 4px;
            }
        }

        input, select, textarea {
            padding: 10px 15px;
            font-family: $font-body;
            font-size: 1.6em;
            color: $primary-black;
            background-color: $primary-white;
            border-radius: 4px;
            border: none;
            outline: none;
            outline-offset: 0;
            transition: all 150ms ease;

            &::placeholder {
                font-size: 1.6em;
                opacity: 0.7;
            }

            &:focus {
                outline: 1px solid $primary-accent;
                outline-offset: 2px;
            }
        }

    }

    .button {
        margin-top: 2em;
        width: 150px;
        cursor: pointer;
        
    }
}