@import '../../index.scss';

.project-card {

    &:hover {
        .project-image {
            img {
                transform: scale(1.1);
            }
        }
    }

    .project-image {
        height: 550px;
        width: 100%;
        background-color: #DED9FF;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 150ms ease;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            transition: transform 150ms ease;
        }


    }

    .project-title {
        color: $primary-white;
        font-family: $font-heading;
        font-size: clamp(2.2em, 3vw, 2.8em);
        font-weight: 500;
        padding-top: 32px;
        padding-bottom: 20px;
    }

    .service-type {

        .service-item {
            color: $primary-white;
            font-size: clamp(1.2em, 2vw, 1.6em);
            padding: 5px 16px;
            margin-right: 8px;
            border: 1px solid $primary-gray;
            border-radius: 100em;
            cursor: default;
        }
    }
}

@media (max-width: 480px) {
    .project-card {
        .project-image {
            width: 100%;
            height: 250px;
            // aspect-ratio: 1/1;
            border-radius: 16px;
        }

        .project-title {
            padding-top: 20px;
        }

    }
}