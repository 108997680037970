@import '../../index.scss';

.contact-us {
    margin-top: 10em;
    margin-bottom: 10em;

    .section-name {
        display: flex;
        align-items: center;
        margin-bottom: clamp(1.6em, 3vw, 2.4em);

        .index {
            height: 24px;
            aspect-ratio: 1/1;
            background-color: $primary-white;
            color: $primary-gray;
            border-radius: 100em;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 1.6em;
        }

        .subtext {
            font-size: 1.4em;
            font-weight: 400;
            padding: 5px 24px;
            margin-left: 8px;
            border: 1px solid $primary-gray;
            border-radius: 100em;
            opacity: 0.7;
        }

    }

    .section-title {
        font-size: clamp(2.8em, 6vw, 6.4em);
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .section-content {
        padding-top: 5em;

        .section-description {
            font-size: clamp(1.6em, 2vw, 2em);
            padding-bottom: 50px;
        }

        .button {
            // float: right;
            display: block;
            width: max-content;
        }
    }
}