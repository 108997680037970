@import '../../index.scss';

.projects {
    margin-bottom: 10em;

    .container {

        .projects-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8em 2em;
            margin: 5em 0;
        }
    }
}

@media (max-width: 480px) {
    .projects {
        .container {
            .projects-grid {
                grid-template-columns: repeat(1, 1fr);
                gap: 6.4em;
            }
        }
    }
}