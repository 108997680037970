@import '../../index.scss';

.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(10px);
    z-index: 99;

    .mobile-nav {
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-container {

            .logo {
                a {
                    font-size: clamp(2.4em, 3vw, 3em);
                    color: $primary-white;
                    text-decoration: none;
                    font-weight: 500;

                    .highlight {
                        color: $primary-accent;
                    }

                    img {
                        height: 40px;
                    }
                }

            }
        }

        .menu-open {

            img {
                height: 20px;
            }

        }

        .isOpen {
            width: 70%;
        }

        .isClosed {
            width: 0;
        }

        .mobile-menu {
            background-color: $primary-white;
            position: fixed;
            top: 0;
            right: 0;
            // width: 0%;
            min-height: 100vh;
            transition: all 350ms ease-in-out;
            overflow-x: hidden;
            
            .menu-close {
                padding: 32px;
                float: right;

                img {
                    height: 20px;
                }
            }

            .main-menu {
                padding: 32px;
                padding-top: 5em;

                li {
                    margin-block: 32px;

                    a {
                        font-size: 1.8em;
                        font-weight: 500;
                        color: $primary-gray;
                        // padding: 8px 25px;
                        border-radius: 100em;
                        transition: all 150ms ease-in-out;

                        &:hover {
                            padding-left: 8px;
                            text-decoration: underline $primary-accent;
                            text-underline-offset: 8px;
                        }

                    }

                    .active {
                        border: 1px solid $secondary-gray;
                    }
                }

            }

        }
    }
}