@import '../../index.scss';

.hero {
    height: 100%;
    width: 100%;
    margin-bottom: 10em;
    
    .hero-content {

        .subtitle {
            font-size: clamp(1.8em, 3vw, 2.8em);
            font-family: $font-body;
            font-weight: 400;
        }

        .title {
            margin-top: 10px;
            font-size: clamp(4.4em, 6.4vw, 9.6em);
            font-family: $font-heading;
        }

        .cta-container {
            margin-top: 50px;

            .button {
                margin-right: 10px;
                display: inline-block;
            }
        }
    }

    .hero-image {
        margin-top: 15em;
        
        img {
            height: 80vh;
            width: 100%;
            border-radius: 25px;
            object-fit: cover;
        }
    }
}

@media (max-width: 480px) {
    .hero {
        .hero-image {
            img {
                height: 250px;
                border-radius: 8px;
            }
        }
    }
}